.Input {
	width: 100%;
	padding: 5px 0;
}

.Label {
	display: block;
	text-align: left;
	color: white;
	margin-bottom: 8px;
}

.InputElement {
	outline: none;
	background-color: transparent;
	font: inherit;
	padding: 6px 10px;
	display: block;
	width: 100%;
	color: #fff;
	border: 3px solid white;
}

.ValidationError {
	color: #fff;
	font-size: calc(16px - 2px);
	font-weight: 100;
}

.InputElement:focus {
	outline: none;
	border: 3px solid red;
}

::-webkit-input-placeholder {
	/* Edge */
	color: white;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: white;
}

::placeholder {
	color: white;
}
