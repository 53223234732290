* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	background-color: #fff;
	line-height: 24px;
	font-size: 16px;
	margin: 0 auto;
	position: relative;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	/* transform-style: preserve-3d; */
	user-select: none;
	overflow: -moz-scrollbars-none;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}

body::-webkit-scrollbar {
	width: 0 !important;
}

h1 {
	color: #000000;
	font-family: "Abril Fatface", cursive;
	font-weight: 400;
	font-size: 49px;
	line-height: 59px;
}

h2 {
	color: #000000;
	display: inline-block;
	margin-bottom: 36px;
	border-bottom: 3px solid #000000;
	padding-bottom: 11px;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
}

h4 {
	font-family: "Abril Fatface";
	color: #000000;
	font-size: 29px;
	line-height: 39px;
	margin-top: 11px;
	margin-bottom: 50px;
	font-weight: 400;
}

h6 {
	color: #fff;
	font-family: "Abril Fatface";
	font-weight: 400;
	font-size: 45px;
	line-height: 60px;
}

address {
	color: #fff;
	margin-left: 10px;
}

a {
	text-decoration: none;
}

p {
	color: #000000;
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 20px;
}

li {
	list-style-type: none;
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	list-style-type: none;
	-webkit-margin-before: 0em;
	-webkit-margin-after: 0em;
	-webkit-margin-start: 0px;
	-webkit-margin-end: 0px;
	-webkit-padding-start: 0px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.social {
	width: inherit;
	height: inherit;
}

.noscroll {
	overflow-x: hidden;
	overflow-y: auto;
}

.contactVideo {
	position: relative;
	max-width: 100%;
	top: -5%;
	z-index: 1;
}

.text_white {
	color: #fff;
}

#jvlabelWrap {
	z-index: 0;
}
